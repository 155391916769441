<template>
    <vs-tabs v-model="tabs">
        <vs-tab label="Suggestion">
            <suggestion @edit="edit"></suggestion>
        </vs-tab>
        <vs-tab label="Edit">
            <div v-if="!allowEdit"></div>
            <edit v-else :deliveryPlan="deliveryPlan" :picking="picking" :date="date" @close="close"></edit>
        </vs-tab>
    </vs-tabs>
</template>
<script>
import edit from "./edit.vue"
import suggestion from "./suggestion.vue"
export default {
    components: {
        suggestion,
        edit
    },
    data() {
        return {
            tabs: 0,
            deliveryPlan: {},
            picking: {},
            date: "",
            allowEdit: false,
        }
    },
    methods: {
        edit(params) {
            this.allowEdit = true
            this.tabs = 1
            this.deliveryPlan = params.dp
            this.picking = params.picking
            this.date = params.date
        },
        close() {
            this.allowEdit = false
            this.tabs = 0
        },
    }
}
</script>