<template>
    <vs-tabs v-model="tabs">
        <vs-tab label="FCO Complete">
            <fco-complete @edit="edit"/>
        </vs-tab>
        <vs-tab label="Form">
            <div v-if="!allowForm"></div>
            <fco-form v-else :handover_id="handoverId" @close="close"/>
        </vs-tab>
    </vs-tabs>
</template>
<script>
import FcoComplete from "./FcoComplete.vue"
import FcoForm from "./Form.vue"
export default {
    components: {
        FcoForm,
        FcoComplete
    },
    data() {
        return {
            tabs: 0,
            handoverId: null,
            allowForm: false,
        }
    },
    methods: {
        edit(params) {
            console.log(params)
            this.allowForm = true
            this.tabs = 1
            this.handoverId = params.handover_id
        },
        close() {
            this.allowForm = false
            this.tabs = 0
        },
    }
}
</script>