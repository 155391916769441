<template>
  <vx-card title="Pickup POD">
    <div class="vx-row" style="width: 100%">
      <div class="vx-col">
        <div class="vx-row mb-6" style="width: 100%">
          <div class="vx-col sm:w-1/3 w-full flex items-center">
            <span>SO Data</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <vs-input
              readonly
              :value="data.SalesOrder.Code"
              class="w-full"
            ></vs-input>
          </div>
        </div>
        <div class="vx-row mb-6" style="width: 100%">
          <div class="vx-col sm:w-1/3 w-full flex items-center">
            <span>DO Data</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <vs-input readonly :value="data.Code" class="w-full"></vs-input>
          </div>
        </div>
        <div class="vx-row mb-6" style="width: 100%">
          <div class="vx-col sm:w-1/3 w-full flex items-center">
            <span>POD Number</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <vs-input
              readonly
              :value="data.ProofOfDelivery.Code"
              class="w-full"
            ></vs-input>
          </div>
        </div>
        <div class="vx-row mb-6" style="width: 100%">
          <div class="vx-col sm:w-1/3 w-full flex items-center">
            <span>POD Date</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <vs-input
              readonly
              :value="data.ProofOfDelivery.Date"
              class="w-full"
            ></vs-input>
          </div>
        </div>

        <div class="vx-row mb-6" style="width: 100%">
          <div class="vx-col sm:w-1/3 w-full flex items-center">
            <span>Posting Date</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <vuejs-datepicker :language="fr"></vuejs-datepicker>
          </div>
        </div>

        <div class="vx-row mb-6" style="width: 100%">
          <div class="vx-col sm:w-1/3 w-full flex items-center">
            <span>POD Status</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <!-- <vs-input readonly :value="data.ProofOfDelivery.Status" class="w-full"></vs-input> -->
            <multiselect
              disabled
              v-model="data.ProofOfDelivery.status"
              :options="status"
              placeholder="Type to search"
              track-by="id"
              label="text"
              :max-height="125"
              @select="logSelect"
            >
            </multiselect>
          </div>
        </div>
      </div>
    </div>
    <br />
    <table class="table table-stripe" v-if="renderTable">
      <thead>
        <th>SKU Name</th>
        <th>HU</th>
        <th>DO Qty</th>
        <th>Batch Number</th>
        <th>Expired Date</th>
        <th>Rejected Qty</th>
        <th>Accepted Qty</th>
        <th>Reason</th>
        <th>Note (optional)</th>
      </thead>
      <tbody>
        <tr :key="indextr" v-for="(tr, indextr) in data.DeliveryOrderLine">
          <td>{{ tr.PackingLine.ItemName }}</td>
          <td>{{ tr.PackingLine.ItemUnit }}</td>
          <td>{{ tr.PackingLine.Amount }}</td>
          <td>{{ tr.PackingLine.Batch }}</td>
          <td>{{ tr.PackingLine.ExpiredDate }}</td>
          <td>
            <vs-input
              disabled
              v-model="tr.ProofOfDeliveryLine.ReturnQuantity"
              @change="(e) => calculateQuantity(e, indextr)"
            ></vs-input>
          </td>
          <!-- <td>{{ tr.PackingLine.Amount - tr.ProofOfDeliveryLine.ReturnQuantity }}</td> -->
          <td>{{ tr.ProofOfDeliveryLine.AdjustedQuantity }}</td>
          <!-- <td>{{ tr.ProofOfDeliveryLine.ReasonID }}</td> -->
          <td>
            <multiselect
              disabled
              v-model="tr.ProofOfDeliveryLine.reason"
              :options="reasons"
              placeholder="Type to search"
              track-by="id"
              label="reason"
              :max-height="125"
              @select="logSelect"
            >
            </multiselect>
          </td>
          <!-- <td>{{ tr.ProofOfDeliveryLine.Note }}</td> -->
          <td>
            <vs-input disabled v-model="tr.ProofOfDeliveryLine.Note"></vs-input>
          </td>
        </tr>
      </tbody>
    </table>
    <br />
    <!-- <div class="mb-6 space-x-1 flex">
            <div class="">
                <vs-button color="primary" @click="handleSave(false)">Save</vs-button>
            </div>
            <div class="">
                <vs-button color="success" @click="handleSave(true)">Save & Confirm</vs-button>
            </div>
        </div> -->
  </vx-card>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import ConfirmDatePlugin from "flatpickr/dist/plugins/confirmDate/confirmDate.js";

import Datepicker from "vuejs-datepicker";

// import Date from "./component/Date.vue";
import moment from "moment";

export default {
  components: {
    // Date,
    flatPickr,

    Datepicker,
  },
  props: {
    do_id: Number,
  },
  data() {
    return {
      status: [
        {
          id: 0,
          text: "Open",
        },
        {
          id: 1,
          text: "Complete",
        },
        {
          id: 2,
          text: "Partial Complete",
        },
        {
          id: 4,
          text: "Failed Cancel",
        },
      ],
      reasons: [],
      renderTable: true,
      table: this.tableDefaultState(),
      data: {
        SalesOrder: {},
        ProofOfDelivery: [],
      },
      // data: null,
      pickingListNumber: null,
      checkedAll: false,
      checked: [],
      rdd: moment().format("D MMMM yyyy"),
      configFlatPickr: {
        altFormat: "F j, Y",
        dateFormat: "j F Y",
        enableTime: false,
        plugins: [new ConfirmDatePlugin()],
      },
    };
  },
  computed: {
    formatDate: () => {
      return (val) => (val ? moment.utc(val).format("YYYY-MM-DD") : "");
    },
  },
  methods: {
    calculateQuantity(event, idx) {
      const returnQuantity = parseInt(event.target.value || "0");
      const podLine = this.data.DeliveryOrderLine[idx].ProofOfDeliveryLine;
      this.data.DeliveryOrderLine[idx].ProofOfDeliveryLine.AdjustedQuantity =
        podLine.Quantity - returnQuantity;
    },
    logSelect(val) {
      console.log(val);
      this.renderTable = false;
      this.$nextTick(() => (this.renderTable = true));
    },
    tableDefaultState() {
      return {
        data: [
          {
            SKUCode: "89898989",
            SKUName: "ULI JONGGOL",
            HU: "uwowowow",
            Quantity: "98246",
            storageArea: "MW9NF9P",
            batchNumber: "823748280374",
            expDate: "24 JANUARI 2027",
          },
        ],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 1,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },
    getReason() {
      this.$http
        .get(`/api/wms/v1/master/reason`, {
          params: { reason_group: "POD" },
        })
        .then((resp) => {
          if (resp.code == 200) {
            console.log("reason", resp.data);
            this.reasons = resp.data.records;
          } else {
            //
          }
        });
    },
    findStatus(statusId) {
      return this.status.filter((s) => s.id == statusId);
    },
    findReason(reasonId) {
      return this.reasons.filter((r) => r.id == reasonId);
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get(`/api/wms/v1/simple-outbound-planner/do/${this.do_id}`)
        .then((resp) => {
          if (resp.code == 200) {
            console.log(resp.data);
            this.data = resp.data;
            this.data.ProofOfDelivery.status = this.findStatus(
              this.data.ProofOfDelivery.StatusDelivery
            );
            this.data.ProofOfDelivery.Date =
              this.data.ProofOfDelivery.Date.split("T")[0];
            this.data.DeliveryOrderLine.forEach((dol, idx) => {
              this.data.DeliveryOrderLine[idx].ProofOfDeliveryLine.reason =
                this.findReason(dol.ProofOfDeliveryLine.ReasonID);
            });
            console.log("data", this.data);
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    // handleSave(confirm) {
    //     console.log("save")
    //     const podLines = this.data.DeliveryOrderLine.map(dol => ({
    //         id: dol.ProofOfDeliveryLine.id,
    //         quantity: dol.ProofOfDeliveryLine.Quantity,
    //         adjusted_quantity: dol.ProofOfDeliveryLine.AdjustedQuantity,
    //         return_quantity: parseInt(dol.ProofOfDeliveryLine.ReturnQuantity || "0"),
    //         reason_id: dol.ProofOfDeliveryLine.reason.id,
    //         note: dol.ProofOfDeliveryLine.Note,
    //     }))
    //     console.log(podLines)
    //     this.$vs.loading();
    //     this.$http
    //         .put(`/api/wms/v1/simple-outbound-planner/pod/${this.do_id}`, {
    //             status_delivery: this.data.ProofOfDelivery.status.id,
    //             pod_lines: podLines,
    //             confirm,
    //         })
    //         .then((resp) => {
    //             if (resp.code == 200) {
    //                 this.$vs.loading.close();
    //                 this.$emit("close")
    //             } else {
    //                 this.$vs.loading.close();
    //             }
    //         })
    // },
  },
  mounted() {
    console.log("mounted");
    this.getReason();
    this.getData();
  },
  watch: {
    // detail() {
    //     this.getData();
    // },
    checkedAll(val) {
      if (val) {
        this.selectedRouteAssignmentToAssign = [];
        this.table.data.forEach((el, index) => {
          this.checked[index] = true;
          this.selectedRouteAssignmentToAssign.push(el);
        });
      } else {
        if (
          this.selectedRouteAssignmentToAssign.length == this.checked.length
        ) {
          this.checked.forEach((el, index) => {
            this.checked[index] = false;
          });
          this.selectedRouteAssignmentToAssign = [];
        }
      }
    },
  },
};
</script>
