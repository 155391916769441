<template>
    <vx-card title="Edit Picking Suggestion Form">
        <div class="vx-row mb-6" style="width: 50%" v-if="readonly">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Delivery Plan Number</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="deliveryPlan.code" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%" v-if="readonly">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Picking List Number</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="picking.code" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Picking Date Date</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <flat-pickr style="width: 100%;" :config="configFlatPickr" v-model="this.pickingDate"></flat-pickr>
            </div>
        </div>
        <vs-table stripe border :data="pickingPlan">
            <template slot="thead">
                <vs-th sort-key="">Delivery Order</vs-th>
                <vs-th sort-key="">Sales Order</vs-th>
                <vs-th sort-key="">Customer Data</vs-th>
                <vs-th sort-key="">Ship-to Data</vs-th>
                <vs-th sort-key="">Request Delivery Date (RDD)</vs-th>
                <vs-th sort-key="">Delivery Type</vs-th>
            </template>

            <template>
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in pickingPlan">
                    <vs-td>{{ tr.SalesOrder.DeliveryOrder.Code }}</vs-td>
                    <vs-td>{{ tr.SalesOrder.Code }}</vs-td>
                    <vs-td>{{ tr.SalesOrder.CustomerName }}</vs-td>
                    <vs-td>{{ tr.SalesOrder.CustomerAddress }}</vs-td>
                    <vs-td>{{ formatDate(tr.SalesOrder.DeliveryOrder.Date) }}</vs-td>
                    <vs-td>{{ tr.SalesOrder.DeliveryType }}</vs-td>
                </vs-tr>
            </template>
        </vs-table>
        <br>
        <br>
        <vs-table style="width:100%" :data="pickingItem">
            <thead>
                <tr>
                    <th style="vertical-align: middle;" rowspan="2">SKU Code</th>
                    <th style="vertical-align: middle;" rowspan="2">SKU Name</th>
                    <th style="vertical-align: middle;" rowspan="2">HU</th>
                    <th style="vertical-align: middle;" rowspan="2">Quantity</th>
                    <th style="text-align: center;" colspan="3">Suggestion</th>
                    <th style="text-align: center;" colspan="3">Actual</th>
                </tr>
                <tr>
                    <th>Storage Area</th>
                    <th>Batch Number</th>
                    <th>Expired Date</th>
                   <th>Storage Area</th>
                    <th>Batch Number</th>
                    <th>Expired Date</th>
                </tr>
            </thead>
            <template>
                <template v-for="(t, indext) in pickingItem">
                    <template v-if="t.PickingItemSuggestion.length <= t.PickingItemStock.length">
                        <vs-tr :key="`${indext}-${indextr}`" v-for="(tr, indextr) in t.PickingItemStock">
                            <vs-td>{{ indextr != 0 ? '' : tr.SkuCode }}</vs-td>
                            <vs-td>{{ indextr != 0 ? '' : tr.ItemName }}</vs-td>
                            <vs-td>{{ indextr >= t.PickingItemSuggestion.length ? '' : t.PickingItemSuggestion[indextr].Unit }}</vs-td>
                            <vs-td>{{ indextr >= t.PickingItemSuggestion.length ? '' : t.PickingItemSuggestion[indextr].Amount }}</vs-td>
                            <vs-td>{{ indextr >= t.PickingItemSuggestion.length ? '' : t.PickingItemSuggestion[indextr].StorageCode }}</vs-td>
                            <vs-td>{{ indextr >= t.PickingItemSuggestion.length ? '' : t.PickingItemSuggestion[indextr].Batch }}</vs-td>
                            <vs-td>{{ indextr >= t.PickingItemSuggestion.length ? '' : formatDate(t.PickingItemSuggestion[indextr].ExpiredDate) }}</vs-td>
                            <vs-td>{{ tr.StorageCode }}</vs-td>
                            <vs-td>{{ tr.Batch }}</vs-td>
                            <vs-td>{{ formatDate(tr.ExpiredDate) }}</vs-td>
                        </vs-tr>
                    </template>
                    <template v-else>
                        <vs-tr :key="`${indext}-${indextr}`" v-for="(tr, indextr) in t.PickingItemSuggestion">
                            <vs-td>{{ indextr != 0 ? '' : tr.SkuCode }}</vs-td>
                            <vs-td>{{ indextr != 0 ? '' : tr.ItemName }}</vs-td>
                            <vs-td>{{ tr.Unit }}</vs-td>
                            <vs-td>{{ tr.Amount }}</vs-td>
                            <vs-td>{{ tr.StorageCode }}</vs-td>
                            <vs-td>{{ tr.Batch }}</vs-td>
                            <vs-td>{{ formatDate(tr.ExpiredDate) }}</vs-td>
                            <vs-td>{{ indextr >= t.PickingItemStock.length || t.PickingItemStock.length == 0 ? '' : t.PickingItemStock[indextr].StorageCode }}</vs-td>
                            <vs-td>{{ indextr >= t.PickingItemStock.length || t.PickingItemStock.length == 0 ? '' : t.PickingItemStock[indextr].Batch }}</vs-td>
                            <vs-td>{{ indextr >= t.PickingItemStock.length || t.PickingItemStock.length == 0 ? '' : formatDate(t.PickingItemStock[indextr].ExpiredDate) }}</vs-td>
                        </vs-tr>
                    </template>
                    <!-- <template v-else>
                        <vs-tr :key="`${indext}-${indextr}`" v-for="(tr, indextr) in t.PickingItemStock">
                            <vs-td>{{ tr.SkuCode }}</vs-td>
                            <vs-td>{{ tr.ItemName }}</vs-td>
                            <vs-td>{{ indextr > t.PickingItemSuggestion.length || t.PickingItemSuggestion.length == 0 ? '' : t.PickingItemSuggestion[indextr].Unit }}</vs-td>
                            <vs-td>{{ indextr > t.PickingItemSuggestion.length || t.PickingItemSuggestion.length == 0 ? '' : t.PickingItemSuggestion[indextr].Amount }}</vs-td>
                            <vs-td>{{ indextr > t.PickingItemSuggestion.length || t.PickingItemSuggestion.length == 0 ? '' : t.PickingItemSuggestion[indextr].StorageCode }}</vs-td>
                            <vs-td>{{ indextr > t.PickingItemSuggestion.length || t.PickingItemSuggestion.length == 0 ? '' : t.PickingItemSuggestion[indextr].Batch }}</vs-td>
                            <vs-td>{{ indextr > t.PickingItemSuggestion.length || t.PickingItemSuggestion.length == 0 ? '' : formatDate(t.PickingItemSuggestion[indextr].ExpiredDate) }}</vs-td>
                            <vs-td>{{ tr.StorageCode }}</vs-td>
                            <vs-td>{{ tr.Batch }}</vs-td>
                            <vs-td>{{ formatDate(tr.ExpiredDate) }}</vs-td>
                        </vs-tr>
                    </template> -->
                </template>
            </template>
        </vs-table>
        <br>
        <div class="mb-6 space-x-1 flex">
            <div class="">
                <vs-button color="primary" @click="handleSave">Close</vs-button>
            </div>
        </div>
    </vx-card>
</template>

<script>
import moment from "moment";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import ConfirmDatePlugin from "flatpickr/dist/plugins/confirmDate/confirmDate.js";

export default {
    components: {
        flatPickr,
    },
    props: {
        baseUrl: {
            type: String,
        },
        draw: {
            type: Number,
        },
        deliveryPlan: Object,
        picking: Object,
        date: String,
    },
    data() {
        return {
            configFlatPickr: {
                altFormat: "F j, Y",
                dateFormat: "j F Y",
                enableTime: false,
                plugins: [new ConfirmDatePlugin()],
            },
            pickingDate: this.date,
            // pickingDate: "",
            readonly: true,
            // table: this.tableDefaultState(),
            pickingPlan: [],
            pickingItem: [],
            stock: [],
            selects: {},
        }
    },
    computed: {
        formatDate: () => {
            return (val) => moment.utc(val).format("YYYY-MM-DD")
        },
    },
    methods: {
        handleSave() {
            this.$emit("close")
        },
    },
    mounted() {
        this.$vs.loading();
        this.$http
            // .get("/api/wms/v1/simple-outbound-planner/suggestion/form", {
            //     params: {
            //         picking_id: this.picking.id,
            //         // picking_date: this.pickingDate,
            //     },
            // })
            .get(`/api/wms/v1/simple-outbound-planner/picking-data/${this.picking.id}`, {
                params: { include_stock: 1 },
            })
            .then((resp) => {
                if (resp.code == 200) {
                    this.pickingPlan = resp.data.PickingPlan
                    this.pickingItem = resp.data.PickingItem
                    this.$vs.loading.close();
                } else {
                    this.$vs.loading.close();
                }
            });
    },
}
</script>

<style>

</style>