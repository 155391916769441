<template>
    <vx-card title="Simple Outbound - Planner">
        <div class="vx-input-group flex">
            <vs-tabs v-if="render" v-model="tab">
                <vs-tab label="Planning" :disabled="setting == 'Auto'">
                    <tab-planning></tab-planning>
                </vs-tab>
                <vs-tab label="Suggestion" :disabled="setting == 'Auto'">
                    <tab-suggestion></tab-suggestion>
                </vs-tab>
                <vs-tab label="Process" :disabled="setting == 'Auto'">
                    <tab-process></tab-process>
                </vs-tab>
                <vs-tab label="Handover">
                    <tab-handover></tab-handover>
                </vs-tab>
                <vs-tab label="Pickup">
                    <tab-pickup></tab-pickup>
                </vs-tab>
                <vs-tab label="Cancel" :disabled="setting == 'Auto'">
                    <tab-cancel></tab-cancel>
                </vs-tab>
                <vs-tab label="DO" :disabled="setting == 'Auto'">
                    <tab-do></tab-do>
                </vs-tab>
                <vs-tab label="Complete">
                    <tab-complete></tab-complete>
                </vs-tab>
            </vs-tabs>
        </div>
    </vx-card>
</template>
<script>
// import TabPlanning from "./TabPlanning2.vue";
import TabPlanning from "./planning/index.vue";
// import TabSuggestion from "./TabSuggestion.vue";
import TabSuggestion from "./suggestion/index.vue";
// import TabProcess from "./TabProcess.vue";
import TabProcess from "./process/index.vue";
// import TabHandover from "./TabHandover.vue";
import TabHandover from "./handover/index.vue";
// import TabPickup from "./TabPickup.vue";
import TabPickup from "./pickup/index.vue";
import TabCancel from "./TabCancel.vue";
import TabDo from "./TabDO.vue";
// import TabComplete from "./TabComplete.vue";
import TabComplete from "./complete/index2.vue";
export default {
    components: {
        TabPlanning,
        TabSuggestion,
        TabProcess,
        TabHandover,
        TabPickup,
        TabCancel,
        TabDo,
        TabComplete,
    },
    data() {
        return {
            activeTab: "Planning",
            dueDate: "",
            setting: "",
            render: true,
            tab: 0,
        }
    },
    mounted() {
        this.$vs.loading()
        this.$http
            .get(`/api/wms/v1/simple-outbound-planner/setting`)
            .then((resp) => {
                if (resp.code == 200) {
                    this.setting = resp.data
                    if (this.setting == "Auto") {
                        this.tab = 3
                    }
                    this.render = false
                    this.$nextTick(() => this.render = true)
                    this.$vs.loading.close()
                }
            })
    },
    methods: {
        // ...
    }
}
</script>