<template>
    <div>
        <div class="vx-row ml-1 mt-3">
            <div class="vx-row mb-6" style="width:70%">
                <div class="vx-col sm:w-1/4 w-full flex items-center">
                    <span>Picking List Number</span>
                </div>
                <div class="vx-col sm:w-3/4 w-full">
                    <Warehouse></Warehouse>
                </div>
            </div>
            <div class="vx-row mb-6" style="width:70%">
                <div class="vx-col sm:w-1/4 w-full flex items-center">
                    <span>Picking Date Date</span>
                </div>
                <div class="vx-col sm:w-3/4 w-full">
                    <Date></Date>
                </div>
            </div>
            <div class="vx-row mb-6" style="width:70%">
                <div class="vx-col sm:w-1/4 w-full flex items-center">
                </div>
                <div class="vx-col sm:w-3/4 w-full">
                    <vs-button color="primary" @click="handleFilter()">Filter</vs-button>
                </div>
            </div>
        </div>
        <div class="vx-row mt-3">
            <div class="vx-col w-full mb-base">
                <TableProcess></TableProcess>
            </div>
        </div>
    </div>
</template>
<script>

import Warehouse from "./component/Warehouse.vue";
import Date from "./component/Date.vue";
import TableProcess from "./component/TableProcess.vue";
export default {
    components: {
        Warehouse,
        Date,
        TableProcess
    },
    data() {
        return {
            activeTab: "DO",
        }
    },
    methods: {
        handleFilter() {

        },
    }
}
</script>