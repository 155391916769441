<template>
    <div>
        <div class="vx-row ml-1 mt-3">
            <div class="vx-row mb-6" style="width:70%">
                <div class="vx-col sm:w-1/4 w-full flex items-center">
                    <span>Picking List Number</span>
                </div>
                <div class="vx-col sm:w-3/4 w-full">
                    <multiselect
                        v-model="pickingNumber"
                        :options="pickingNumbers"
                        placeholder="Type to search"
                        track-by="code"
                        label="code"
                        :max-height="125"
                    ></multiselect>
                </div>
            </div>
            <div class="vx-row mb-6" style="width:70%">
                <div class="vx-col sm:w-1/4 w-full flex items-center">
                    <span>Picking Date Date</span>
                </div>
                <div class="vx-col sm:w-3/4 w-full">
                    <flat-pickr style="width: 100%;" :config="configFlatPickr" v-model="pickingDate"></flat-pickr>
                </div>
            </div>
            <div class="vx-row mb-6" style="width:70%">
                <div class="vx-col sm:w-1/4 w-full flex items-center">
                </div>
                <div class="vx-col sm:w-3/4 w-full">
                    <vs-button color="primary" @click="handleFilter()">Filter</vs-button>
                </div>
            </div>
        </div>
        <div class="vx-row mt-3">
            <div class="vx-col w-full mb-base">
                <vs-table search stripe border description :sst="true" :data="table.data" :max-items="table.length"
                    :total="table.total" @search="handleSearch" @change-page="handleChangePage" @sort="handleSort">
                    <template slot="header">
                        <vs-dropdown vs-trigger-click class="cursor-pointer">
                            <div
                                class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                                <span class="mr-2">{{ this.table.start }} - {{ this.table.end }} of
                                    {{ this.table.total }}</span>
                                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                            </div>
                            <vs-dropdown-menu>
                                <vs-dropdown-item v-for="item in table.limits" :key="item" @click="handleChangelength(item)">
                                    <span>{{ item }}</span>
                                </vs-dropdown-item>
                            </vs-dropdown-menu>
                        </vs-dropdown>
                    </template>
                    <template slot="thead">
                        <vs-th>Select</vs-th>
                        <vs-th sort-key="">Delivery Plan</vs-th>
                        <vs-th sort-key="">Picking List Data</vs-th>
                        <vs-th sort-key="">Delivery Order</vs-th>
                        <vs-th sort-key="">Sales Order</vs-th>
                        <vs-th sort-key="">Customer Data</vs-th>
                        <vs-th sort-key="">Ship-to Data</vs-th>
                        <vs-th sort-key="">Request Delivery Date (RDD)</vs-th>
                        <vs-th sort-key="">Delivery Type</vs-th>
                    </template>

                    <template slot-scope="{ data }">
                        <template v-for="(t, indext) in data">
                            <vs-tr :key="`${indext}-${indextr}`" v-for="(tr, indextr) in t.PickingPlan">
                                <vs-td>
                                    <div class="mb-6 space-x-1 flex" v-if="indextr == 0">
                                        <div>
                                            <vs-button
                                            @click="() => $emit('edit', {
                                                picking: {id: t.id, code: t.Code},
                                                dp: {id: tr.SalesOrder.DeliveryPlanLine.DeliveryPlan.id, code: tr.SalesOrder.DeliveryPlanLine.DeliveryPlan.Code},
                                                date: pickingDate,
                                            })"
                                            size="small"
                                            color="warning"
                                            icon-pack="feather"
                                            icon="icon-eye"
                                            title="Edit"
                                            style="margin-right: 5px"/>
                                        </div>
                                    </div>
                                </vs-td>
                                <vs-td>{{ indextr == 0 ? tr.SalesOrder.DeliveryPlanLine.DeliveryPlan.Code : '' }}</vs-td>
                                <vs-td>{{ indextr == 0 ? t.Code: '' }}</vs-td>
                                <vs-td>{{ tr.SalesOrder.DeliveryOrder.Code }}</vs-td>
                                <vs-td>{{ tr.SalesOrder.Code }}</vs-td>
                                <vs-td>{{ tr.SalesOrder.CustomerCode }}, {{ tr.SalesOrder.CustomerName }}</vs-td>
                                <vs-td>{{tr.SalesOrder.CustomerAddress}}</vs-td>
                                <vs-td>{{ formatDate(t.Date) }}</vs-td>
                                <vs-td>{{ tr.SalesOrder.DeliveryType }}</vs-td>
                            </vs-tr>
                        </template>
                    </template>
                </vs-table>
                <div class="mb-6 space-x-1 flex">
                    <div class="">
                        <vs-button color="primary" @click="release">Release</vs-button>
                    </div>
                </div>
                <vs-pagination style="padding-top: 5px" :total="table.totalPage" v-model="setPage" />
            </div>
        </div>
    </div>
</template>
<script>

// import Warehouse from "./component/Warehouse.vue";
// import Date from "./component/Date.vue";
// import TableSuggestion from "./component/TableSuggestion.vue";
import moment from "moment"
import Datepicker from "vuejs-datepicker";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import ConfirmDatePlugin from "flatpickr/dist/plugins/confirmDate/confirmDate.js";
export default {
    components: {
        // Warehouse,
        // Date,
        // TableSuggestion
        Datepicker,
        flatPickr,
    },
    data() {
        return {
            activeTab: "New",
            pickingNumbers: [{id: 0, code: "All"}],
            pickingNumber: {id: 0, code: "All"},
            pickingDate: null,
            selected: [],
            selectAll : false,
            configFlatPickr: {
                altFormat: "F j, Y",
                dateFormat: "j F Y",
                enableTime: false,
                // minTime: "00:00",
                plugins: [new ConfirmDatePlugin()],
            },
            table: this.tableDefaultState(),
            checkedAll: false,
        }
    },
    computed: {
        setPage: {
            get() {
                return 1;
            },
            set(val) {
                this.handleChangePage(val);
            },
        },
        formatDate: () => {
            return (val) => moment.utc(val).format("YYYY-MM-DD")
        },
    },
    mounted() {
        this.$vs.loading()
        this.$http
            // .get("/api/wms/v1/simple-outbound-planner/picking", {params: {status_picking: "2"}})
            // .get("/api/wms/v1/master/picking", {params: {status_picking: "2"}})
            // .get("/api/wms/v1/master/picking", {params: {status_picking: 0, status_release: 1}})
            .get("/api/wms/v1/master/picking", {params: {status_pickings: [2,3], status_release: 1}})
            .then(res => {
                if (res.code == 200) {
                    this.pickingNumbers = [{id: 0, code: "All"}, ...res.data.records]
                }
                this.$vs.loading.close();
            })
    },
    methods: {
        tableDefaultState() {
            return {
                data: [{}],
                length: 10,
                page: 1,
                search: "",
                order: "id",
                sort: "desc",
                total: 0,
                totalPage: 1,
                totalSearch: 0,
                limits: [10, 25, 50, 100, "All"],
                start: 1,
                end: 0,
            };
        },
        handleSearch(searching) {
            this.table.search = searching;
            this.table.page = 1;
            this.getData();
        },
        handleChangePage(page) {
            this.table.page = page;
            this.getData();
        },
        handleSort(key, active) {
            this.table.order = key;
            this.table.sort = active;
            this.getData();
        },
        handleChangelength(val) {
            this.table.length = val == "All" ? this.table.total : val;
            this.table.page = 1;
            this.getData();
        },
        getData() {
            console.log("getData")
            this.$vs.loading();
            this.$http
                .get("/api/wms/v1/simple-outbound-planner/picking-data", {
                    params: {
                        length: this.table.length,
                        page: this.table.page,
                        search: this.table.search,
                        order: this.table.order,
                        sort: this.table.sort,
                        picking_id: this.pickingNumber.id,
                        picking_date: this.pickingDate,
                        // status_picking: 2,
                        // status_picking: 0,
                        status_pickings: [2,3],
                        status_release: 1,
                    },
                })
                .then((resp) => {
                    console.log(resp)
                    if (resp.code == 200) {
                        this.checkedAll = false;
                        this.selectedRouteAssignmentToAssign = [];

                        this.table.total = resp.data.total_record;
                        this.table.totalPage = resp.data.total_page;
                        this.table.totalSearch = resp.data.total_record_search;
                        this.table.length = resp.data.total_record_per_page;
                        this.table.data = resp.data.records;
                        this.setStartEnd();
                        this.$vs.loading.close();
                    } else {
                        this.$vs.loading.close();
                    }
                });
        },
        setStartEnd() {
            let valStart =
                this.table.length * this.table.page - this.table.length + 1;

            if (valStart > this.table.total) {
                valStart = 1;
            }
            if (this.table.total == 0) {
                valStart = 0;
            }
            let valEnd = this.table.length * this.table.page;

            if (valEnd > this.table.total) {
                valEnd = this.table.total;
            }

            if (
                this.table.totalSearch < this.table.total &&
                this.table.search != ""
            ) {
                valEnd = this.table.totalSearch;
            }

            this.table.start = valStart;
            this.table.end = valEnd;
        },
        handleFilter() {
            this.getData()
        },
        addChecked(pickingId) {
            if (!this.selected.includes(pickingId)) {
                this.selected.push(pickingId)
            } else {
                this.selected.splice(this.selected.indexOf(pickingId), 1)
            }
        },
        release() {
            this.$http
                .patch("/api/wms/v1/simple-outbound-planner/suggestion/release", {picking_ids: this.selected})
                .then((resp) => {
                    if (resp.code == 200) {
                        this.$vs.loading.close();
                    } else {
                        this.$vs.loading.close();
                    }
                })
        }
    }
}
</script>