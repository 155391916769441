<template>
    <div>
        <div class="vx-row ml-1 mt-3">
            <div class="vx-row mb-6" style="width:70%">
                <div class="vx-col sm:w-1/4 w-full flex items-center">
                    <span>Delivery Date</span>
                </div>
                <!-- <div class="vx-col sm:w-3/4 w-full">
                    <flat-pickr style="width: 100%;" :config="configFlatPickr" v-model="dueDate"></flat-pickr>
                </div> -->
                <div class="vx-col sm:w-3/4 w-full">
                    <date-range-picker
                        style="min-height: 40px"
                        class="w-full rounded border-none"
                        ref="picker"
                        opens="center"
                        :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
                        singleDatePicker="range"
                        :timePicker="false"
                        :timePicker24Hour="false"
                        :showWeekNumbers="false"
                        :showDropdowns="false"
                        :autoApply="true"
                        v-model="dueDate"
                        :linkedCalendars="false"
                        :ranges="false"
                    >
                        <template v-slot:input="picker">
                            <div>
                                {{ formatDate(picker.startDate) }} - {{ formatDate(picker.endDate) }}
                            </div>
                        </template>
                    </date-range-picker>
                </div>
            </div>
            <div class="vx-row mb-6" style="width:70%">
                <div class="vx-col sm:w-1/4 w-full flex items-center">
                    <span>Driver</span>
                </div>
                <div class="vx-col sm:w-3/4 w-full">
                    <multiselect
                        v-model="driver"
                        :options="drivers"
                        placeholder="Type to search"
                        track-by="text"
                        label="text"
                        :max-height="125"
                    ></multiselect>
                </div>
            </div>
            <div class="vx-row mb-6" style="width:70%">
                <div class="vx-col sm:w-1/4 w-full flex items-center">
                </div>
                <div class="vx-col sm:w-3/4 w-full">
                    <vs-button 
                    color="primary" 
                    v-on:click="reloadData(params)"
                    >Filter</vs-button>
                </div>
            </div>
        </div>
        <div class="vx-row">
            <div class="flex flex-start ml-5" style="width:50%">
                <v-select style="width:100px" :options="table.limits" :clearable="false"  @input="handleChangelength" v-model="params.length" :dir="$vs.rtl ? 'rtl' : 'ltr'" />
                <!-- <v-select style="width:100px"/> -->
            </div>
            <div class="mr-auto"></div>
            <div class="flex flex-end  " >
                <div class="flex flex-end ">
                <vs-input
                    class="mb-4 md:mb-0 mr-4 round"
                    v-model="params.search"
                    @keyup="handleSearch"
                    placeholder="Search..."
                />
                <!-- <vs-input
                    class="mb-4 md:mb-0 mr-4 round"
                    placeholder="Search..."
                /> -->
                </div>
            </div>
        </div>
        <div class="vx-row mt-3">
            <div class="vx-col w-full mb-base flex flex-col" v-if="reRenderTable">
                <table class="table table-stripe">
                    <thead>
                        <tr>
                            <th>Select All</th>
                            <th>Shipment</th>
                            <!-- <th>Picking Method</th> -->
                            <th>Delivery Type</th>
                            <th>#</th>
                            <!-- <th>SO</th> -->
                            <th>Sales Order</th>
                            <th>Ship-to Data</th>
                            <th>Delivery Order</th>
                            <th>Request Delivery Date (RDD)</th>
                            <!-- <th>Delivery Type</th> -->
                        </tr>
                    </thead>
                    <tbody>
                        <template v-if="responseData.delivery_type_id == 1">
                            <template v-for="(d, idx) in responseData.records">
                                <tr :key="`outerTable-${idx}`" v-if="d.TransportPlanLine.length">
                                    <td>
                                        <!-- <vs-checkbox :checked="checkedDp.includes(d.Code)" @click="addCheckedDp(d.Code)"></vs-checkbox> -->
                                        <vs-checkbox :checked="checkedShipment.includes(d.id)" @click="addCheckedShipment(d.id)"></vs-checkbox>
                                    </td>
                                    <td>
                                        <!-- {{d.Code}} -->
                                        {{d.ShipmentNumber}}
                                    </td>
                                    <!-- <td>{{ d.PickingMethod }}</td> -->
                                    <td>{{ d.TransportPlanLine[0].TransportDeliveryPlanLine.SalesOrder.DeliveryType }}</td>
                                    <td>
                                        <!-- <vs-button
                                            @click="expand(d.Code)"
                                            icon-pack="feather"
                                            :icon="expands[d.Code] ? 'icon-chevrons-down' : 'icon-chevron-right'"
                                            type="flat"
                                            icon-after
                                            color="dark"
                                        /> -->
                                        <vs-button
                                            @click="expand(d.id)"
                                            icon-pack="feather"
                                            :icon="expands[d.id] ? 'icon-chevrons-down' : 'icon-chevron-right'"
                                            type="flat"
                                            icon-after
                                            color="dark"
                                        />
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <!-- <template v-if="expands[d.Code]"> -->
                                <template v-if="expands[d.id] && d.TransportPlanLine.length">
                                    <tr v-for="(d2, idx2) in d.TransportPlanLine" :key="`innerTable-${idx}-${idx2}`">
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td class="flex flex-row">
                                            <!-- <vs-checkbox :checked="checkedSo.includes(d2.SalesOrder.Code)" @click="addCheckedSo(d2.SalesOrder.Code, d.Code)">{{ d2.SalesOrder.Code }}</vs-checkbox> -->
                                            {{ d2.TransportDeliveryPlanLine.SalesOrder.Code }}
                                        </td>
                                        <!-- <td>{{ d2.SalesOrder.CustomerCode }}, {{ d2.SalesOrder.CustomerName }}</td> -->
                                        <td>{{ d2.TransportDeliveryPlanLine.SalesOrder.CustomerCode }}, {{ d2.TransportDeliveryPlanLine.SalesOrder.CustomerName }}</td>
                                        <!-- <td>
                                            {{ d2.SalesOrder.CustomerCountry }}, {{ d2.SalesOrder.CustomerProvince }}, {{ d2.SalesOrder.CustomerCity }}
                                            <br>{{ d2.SalesOrder.CustomerDistrict }}
                                            <br>{{ d2.SalesOrder.CustomerSubDistrict }}
                                            <br>{{ d2.SalesOrder.CustomerAddress }}
                                            <br>{{ d2.SalesOrder.CustomerPhone }}
                                            <br>{{ d2.SalesOrder.CustomerMobile }}
                                        </td> -->
                                        <!-- <td>{{ d2.SalesOrder.DeliveryOrder.Code }}</td> -->
                                        <td>{{ d2.TransportDeliveryPlanLine.DeliveryOrder.Code }}</td>
                                        <!-- <td>{{ formatDate(d2.SalesOrder.DueDate) }}</td> -->
                                        <td>{{ formatDate(d2.TransportDeliveryPlanLine.SalesOrder.DueDate) }}</td>
                                        <!-- <td>{{ d2.SalesOrder.DeliveryTypeCode || 'FCO'  }}</td> -->
                                    </tr>
                                </template>
                            </template>
                        </template>
                        <template v-else>
                            <template v-for="(d, idx) in responseData.records">
                                <tr :key="`outerTable-${idx}`" v-if="d.DeliveryPlanLine.length">
                                    <td>
                                        <vs-checkbox :checked="checkedDp.includes(d.id)" @click="addCheckedDp(d.id)"></vs-checkbox>
                                        <!-- <vs-checkbox :checked="checkedShipment.includes(d.id)" @click="addCheckedShipment(d.id)"></vs-checkbox> -->
                                    </td>
                                    <td>
                                        {{d.Code}}
                                        <!-- {{d.ShipmentNumber}} -->
                                    </td>
                                    <!-- <td>{{ d.PickingMethod }}</td> -->
                                    <td>{{ d.DeliveryPlanLine[0].SalesOrder.DeliveryType }}</td>
                                    <!-- <td>{{ d.TransportPlanLine[0].TransportDeliveryPlanLine.SalesOrder.DeliveryType }}</td> -->
                                    <td>
                                        <!-- <vs-button
                                            @click="expand(d.Code)"
                                            icon-pack="feather"
                                            :icon="expands[d.Code] ? 'icon-chevrons-down' : 'icon-chevron-right'"
                                            type="flat"
                                            icon-after
                                            color="dark"
                                        /> -->
                                        <vs-button
                                            @click="expand(d.id)"
                                            icon-pack="feather"
                                            :icon="expands[d.id] ? 'icon-chevrons-down' : 'icon-chevron-right'"
                                            type="flat"
                                            icon-after
                                            color="dark"
                                        />
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <!-- <template v-if="expands[d.Code]"> -->
                                <template v-if="expands[d.id] && d.DeliveryPlanLine.length">
                                    <tr v-for="(d2, idx2) in d.DeliveryPlanLine" :key="`innerTable-${idx}-${idx2}`">
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td class="flex flex-row">
                                            <!-- <vs-checkbox :checked="checkedSo.includes(d2.SalesOrder.Code)" @click="addCheckedSo(d2.SalesOrder.Code, d.Code)">{{ d2.SalesOrder.Code }}</vs-checkbox> -->
                                            {{ d2.SalesOrder.Code }}
                                        </td>
                                        <td>{{ d2.SalesOrder.CustomerCode }}, {{ d2.SalesOrder.CustomerName }}</td>
                                        <!-- <td>{{ d2.TransportDeliveryPlanLine.SalesOrder.CustomerCode }}, {{ d2.TransportDeliveryPlanLine.SalesOrder.CustomerName }}</td> -->
                                        <!-- <td>
                                            {{ d2.SalesOrder.CustomerCountry }}, {{ d2.SalesOrder.CustomerProvince }}, {{ d2.SalesOrder.CustomerCity }}
                                            <br>{{ d2.SalesOrder.CustomerDistrict }}
                                            <br>{{ d2.SalesOrder.CustomerSubDistrict }}
                                            <br>{{ d2.SalesOrder.CustomerAddress }}
                                            <br>{{ d2.SalesOrder.CustomerPhone }}
                                            <br>{{ d2.SalesOrder.CustomerMobile }}
                                        </td> -->
                                        <td>{{ d2.DeliveryOrder.Code }}</td>
                                        <!-- <td>{{ d2.TransportDeliveryPlanLine.DeliveryOrder.Code }}</td> -->
                                        <td>{{ formatDate(d2.SalesOrder.DueDate) }}</td>
                                        <!-- <td>{{ formatDate(d2.TransportDeliveryPlanLine.SalesOrder.DueDate) }}</td> -->
                                        <!-- <td>{{ d2.SalesOrder.DeliveryTypeCode || 'FCO'  }}</td> -->
                                    </tr>
                                </template>
                            </template>
                        </template>
                    </tbody>
                </table>
                <vs-pagination style="padding-top: 5px" :total="table.totalPage" v-model="setPage"/>
                <div class="mb-6 space-x-1 flex">
                    <div class="">
                        <vs-button color="primary" @click="handlePlan()">PLAN</vs-button>
                    </div>
                    <div class="">
                        <vs-button color="danger" @click="handleCancel()">Cancel</vs-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DateRangePicker from "vue2-daterange-picker";
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import ConfirmDatePlugin from "flatpickr/dist/plugins/confirmDate/confirmDate.js";
import vSelect from "vue-select";
import moment from "moment";
import Swal from 'sweetalert2'

export default {
    components: {
        flatPickr,
        vSelect,
        DateRangePicker
    },
    props: {
        fcoID: Number,
    },
    data() {
        return {
            reRenderTable: true,
            expands: {},
            activeTab: "Planning",
            deliveryPlans: {
                selected: {},
                options: [{id: 0, code: "All"}],
            },
            shipmentNumbers: {
                selected: {},
                options: [{id: 0, code: "All"}],
            },
            dueDate: {
                startDate: Date.now(),
                endDate: Date.now(),
            },
            configFlatPickr: {
                altFormat: "F j, Y",
                dateFormat: "j F Y",
                enableTime: false,
                plugins: [new ConfirmDatePlugin()],
            },
            responseData: {},
            table: this.tableDefaultState(),
            checkedAll: false,
            checked: [],
            checkedDp: [],
            checkedSo: [],
            checkedShipment: [],
            checkedCode: [],
            selectedDeliveryPlanCodes: [],
            selectedDeliveryOrders: [],
            selectedRouteAssignmentToAssign: [],
            params: {
                search: "",
                length: 10,
                page: 1,
                order:'',
                sort:'',
            },
            driver: {text: "All", value: 0},
            drivers: [{text: "All", value: 0}],
        }
    },
    mounted() {
        this.$vs.loading();
        this.$http.get("/api/wms/v1/simple-outbound-planner/driver").then(resp => {
            if (resp.code == 200) {
                console.log(resp.data)
                this.drivers = [{text: "All", value: 0}, ...resp.data.map(d => ({text: d.name, value: d.id}))]
                this.$vs.loading.close();
            }
        });
    },
    methods: {
        expand(code) {
            console.log("expand", code)
            this.expands[code] = !this.expands[code]
            this.reRenderTable = false
            this.$nextTick(() => this.reRenderTable = true)
        },
        tableDefaultState() {
            return {
                data: [],
                length: 10,
                page: 1,
                search: "",
                order: "id",
                sort: "desc",
                total: 0,
                totalPage: 1,
                totalSearch: 0,
                limits: [2, 10, 25, 50, 100, "All"],
                start: 1,
                end: 0,
            };
        },
        handleSearch(e) {
            this.table.search = e.target.value;
            this.table.page = 1;
            this.reloadData(this.params);
        },
        handleChangePage(page) {
            if (this.table.page != page) {
                this.table.page = page;
                this.reloadData(this.params);
            }
        },
        handleSort(key, active) {
            this.table.order = key;
            this.table.sort = active;
            this.reloadData(this.params);
        },
        handleChangelength(val) {
            this.table.length = val == "All" ? this.table.total : val;
            this.table.page = 1;
            this.reloadData(this.params);
        },
        reloadData(params) {
            console.log("id", this.fcoID)
            if (this.deliveryPlans.selected) {
                this.params = params;
                this.$vs.loading();
                this.$http
                    .get("/api/wms/v1/simple-outbound-planner/get-data", {
                        params: {
                            delivery_plan_id: this.deliveryPlans.selected.id,
                            // delivery_date: this.dueDate,
                            from_delivery_date: this.formatDate(this.dueDate.startDate),
                            to_delivery_date: this.formatDate(this.dueDate.endDate),
                            delivery_type_id: this.fcoID,
                            driver_id: this.driver.value,

                            length: this.table.length,
                            page: this.table.page,
                            search: this.table.search,
                            order: this.table.order,
                            sort: this.table.sort,
                        }
                    })
                    .then(resp => {
                        if (resp.code == 500) {
                            this.$vs.loading.close();
                        } else if ( resp.code == 200) {
                            this.checkedDp = []
                            this.checkedSo = []
                            this.checkedShipment = []
                            this.$vs.loading.close();
                            this.table.total = resp.data.total_record;
                            this.table.totalPage = resp.data.total_page;
                            this.table.page = resp.data.page;
                            this.table.totalSearch = resp.data.total_record_search;
                            this.table.length = resp.data.total_record_per_page;
                            this.table.data = resp.data.records;
                            this.table.delivery_type_id = resp.data.delivery_type_id;
                            resp.data.records.forEach(d => {
                                this.expands[`${d.Code}`] = false
                            });
                            this.responseData = resp.data;
                            this.setStartEnd();
                            this.checkedAll = false;
                            this.reRenderTable = false
                            this.$nextTick(() => this.reRenderTable = true)
                        } else {
                            this.$vs.loading.close()
                        }
                    });
            }
        },
        setStartEnd() {
            let valStart =
                this.table.length * this.table.page - this.table.length + 1;

            if (valStart > this.table.total) {
                valStart = 1;
            }
            if (this.table.total == 0) {
                valStart = 0;
            }
            let valEnd = this.table.length * this.table.page;

            if (valEnd > this.table.total) {
                valEnd = this.table.total;
            }

            if (
                this.table.totalSearch < this.table.total &&
                this.table.search != ""
            ) {
                valEnd = this.table.totalSearch;
            }

            this.table.start = valStart;
            this.table.end = valEnd;
        },
        handleSearchChange(searchTerm) {
            // this.$http
            //     // .get("/api/wms/v1/simple-outbound-planner/delivery-plan-code", {
            //     .get("/api/wms/v1/master/delivery-plan", {
            //         params: {
            //             search: searchTerm,
            //         },
            //     })
            //         .then((resp) => {
            //         if (resp.code == 200) {
            //             this.deliveryPlans.options = [{id: 0, code: "All"}, ...resp.data.records];
            //             this.$vs.loading.close();
            //         } else {
            //         }
            //     });
            this.$http
                .get("/api/wms/v1/simple-outbound-planner/master/shipment-number", {
                    params: {
                        search: searchTerm,
                    },
                })
                    .then((resp) => {
                    if (resp.code == 200) {
                        this.shipmentNumbers.options = [{id: 0, code: "All"}, ...resp.data.records.map(sn => ({id: sn.id, code: sn.shipment_number}))];
                        this.$vs.loading.close();
                    } else {
                    }
                });
        },
        handleSearchClear() {
            this.deliveryPlans.options = [];
        },
        handlePlan() {
            Swal.fire({
                title: 'Are you sure want to Plan checked data?',
                text: "I hope you know what you do",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'PLAN'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$vs.loading();
                    // var delivery_plan_code = [];
                    // this.selectedDeliveryPlanCodes.forEach(dpc => {
                    //     delivery_plan_code.push(dpc);
                    // });
                    // var delivery_order_code = [];
                    // this.selectedDeliveryOrders.forEach(doc => {
                    //     delivery_order_code.push(doc.DeliveryOrderCode);
                    // });
                    this.$http
                        .post("/api/wms/v1/simple-outbound-planner/plan-data", {
                            // ...
                            // delivery_plan_code: this.checkedDp,
                            // sales_order_code: this.checkedSo,
                            shipment_number_ids: this.checkedShipment,
                            delivery_plan_ids: this.checkedDp,
                            delivery_type_id: this.fcoID,
                        })
                        .then(resp => {
                            if (resp.code == 500) {
                                this.$vs.loading.close()
                                Swal.fire(
                                    'Something gone wrong', 
                                    'CODE : 500', 
                                    'info'
                                )
                            } else if ( resp.code == 200) {
                                this.$vs.loading.close()
                                Swal.fire(
                                    'Done!',
                                    'Checked data has been sent.',
                                    'success'
                                )
                                this.checkedAll = false;
                                this.checked = [];
                                this.checkedDp = [];
                                this.checkedSo = [];
                                this.checkedShipment = []
                                this.checkedCode = [];
                                this.selectedDeliveryPlanCodes = [];
                                this.selectedDeliveryOrders = [];
                                this.reloadData(this.params)
                                // window.location.reload();
                            } else {
                                this.$vs.loading.close()
                                Swal.fire(
                                    'Something gone wrong', 
                                    'ERROR : UNKNOWN (BLACKHOLE OCCURED)', 
                                    'info'
                                )
                            }
                        });
                }
            })
        },
        handleCancel() {
            Swal.fire({
                title: 'Are you sure to cancel Delivery Plan data?',
                text: "I hope you know what you do",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, cancel it!',
                cancelButtonText: 'no'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$vs.loading();
                    this.$http
                        .post("/api/wms/v1/simple-outbound-planner/cancel-data", {
                            // ...
                            // delivery_plan_code: this.checkedDp,
                            // sales_order_code: this.checkedSo,
                            shipment_number_ids: this.checkedShipment
                        })
                        .then(resp => {
                            if (resp.code == 500) {
                                this.$vs.loading.close()
                                Swal.fire(
                                    'Something gone wrong', 
                                    'CODE : 500', 
                                    'info'
                                )
                            } else if ( resp.code == 200) {
                                this.$vs.loading.close()
                                Swal.fire(
                                    'Canceled!',
                                    'Data go back to Fullfilment.',
                                    'success'
                                )
                                this.checkedAll = false;
                                this.checked = [];
                                this.checkedCode = [];
                                this.checkedDp = []
                                this.checkedSo = []
                                this.checkedShipment = []
                                this.selectedDeliveryPlanCodes = [];
                                this.selectedDeliveryOrders = [];
                                this.reloadData(this.params)
                                // window.location.reload();
                            } else {
                                this.$vs.loading.close()
                                Swal.fire(
                                    'Something gone wrong', 
                                    'ERROR : UNKNOWN (BLACKHOLE OCCURED)', 
                                    'info'
                                )
                            }
                        });
                }
            })
        },
        addChecked(val) {
            if (this.checked.includes(val.ID)) {
                this.checked.splice(this.checked.indexOf(val.ID), 1);
                this.checkedCode.splice(this.checkedCode.indexOf(val.ID), 1);
                const index = this.selectedDeliveryOrders.findIndex(order => order.DeliveryOrderCode === val.DeliveryOrderCode);
                if (index !== -1) {
                    this.selectedDeliveryOrders.splice(index, 1);
                }
            } else {
                this.checked.push(val.ID);
                this.checkedCode.push(val.ID);
                const index = this.selectedDeliveryOrders.findIndex(order => order.DeliveryOrderCode === val.DeliveryOrderCode);
                if (index === -1) {
                    this.selectedDeliveryOrders.push({
                    DeliveryOrderCode: val.DeliveryOrderCode,
                    });
                }
            }
        },
        addCheckedTest(tr, idx) {
            this.responseData.records[idx].selected = !this.responseData.records[idx].selected
        },
        addCheckedDp(dpCode) {
            // const soCodes = this.responseData.records[this.responseData.records.findIndex(dp => dp.Code == dpCode)].DeliveryPlanLine.map(dpl => dpl.SalesOrder.Code)
            // if (!this.checkedDp.includes(dpCode)) {
            //     this.checkedDp.push(dpCode)
            //     this.checkedSo.push(...soCodes)
                
            // } else {
            //     this.checkedDp.splice(this.checkedDp.indexOf(dpCode), 1)
            //     this.checkedSo = this.checkedSo.filter(so => !soCodes.includes(so))
            // }
            if (!this.checkedDp.includes(dpCode)) {
                this.checkedDp.push(dpCode)
            } else {
                this.checkedDp.splice(this.checkedDp.indexOf(dpCode), 1)
            }
            this.reRenderTable = false
            this.$nextTick(() => {
                this.reRenderTable = true
            })
        },
        addCheckedShipment(shipmentNumber) {
            if (!this.checkedShipment.includes(shipmentNumber)) {
                this.checkedShipment.push(shipmentNumber)
            } else {
                this.checkedShipment.splice(this.checkedShipment.indexOf(shipmentNumber), 1)
            }
            this.reRenderTable = false
            this.$nextTick(() => {
                this.reRenderTable = true
            })
        },
        addCheckedSo(soCode, dpCode) {
            if (!this.checkedSo.includes(soCode)) {
                this.checkedSo.push(soCode)
            } else {
                this.checkedSo.splice(this.checkedSo.indexOf(soCode), 1)
                this.checkedDp.splice(this.checkedDp.indexOf(dpCode), 1)
            }
            const soCodes = this.responseData.records[this.responseData.records.findIndex(dp => dp.Code == dpCode)].DeliveryPlanLine.map(dpl => dpl.SalesOrder.Code)
            let checkDp = soCodes.every(s => this.checkedSo.includes(s))
            if (checkDp) {
                this.checkedDp.push(dpCode)
            }
        },
        addAllChecked() {
            if (this.checkedAll == false) {
		        for (var i = 0; i < this.table.data.length; i++) {
		            if (!this.checked.includes(this.table.data[i].ID)) {
		                this.checked.push(this.table.data[i].ID);
		                this.checkedCode.push(this.table.data[i].ID);
                        if (!this.selectedDeliveryOrders.find(order => order.DeliveryOrderCode === this.table.data[i].DeliveryOrderCode)) {
                            this.selectedDeliveryOrders.push({
                                DeliveryOrderCode: this.table.data[i].DeliveryOrderCode,
                            });

                            this.selectedDeliveryPlanCodes.push(this.table.data[i].Code);
                        }
		            }
		        }
		    } else {
		        if (!this.isExpandableTableAllChecked) {
		            this.checkedAll = false;
		        }
		        for (var j = 0; j < this.table.data.length; j++) {
		            this.checked.splice(this.checked.indexOf(this.table.data[j].ID), 1);
		            this.checkedCode.splice(this.checkedCode.indexOf(this.table.data[j].ID), 1);
                    const index = this.selectedDeliveryOrders.findIndex(order => order.DeliveryOrderCode === this.table.data[j].DeliveryOrderCode);
                    if (index !== -1) {
                        this.selectedDeliveryOrders.splice(index, 1);

                        const codeIndex = this.selectedDeliveryPlanCodes.indexOf(this.table.data[j].Code);
                        if (codeIndex !== -1) {
                            this.selectedDeliveryPlanCodes.splice(codeIndex, 1);
                        }
                    }
		        }
		    }
        },
        utcToLocal(val) {
            if (val) {
                return moment(val)
                    .add(-7, "h")
                    .utc()
                    .local()
                    .format("DD MMM YYYY HH:mm:ss");
            }
        },
        onchangeSelectedRouteAssignmentToAssign(e, index, row) {
            if (!this.checked[index]) {
                var fitered = this.selectedRouteAssignmentToAssign.filter((d) => {
                    return d.id == row.id;
                });

                if (fitered.length == 0) {
                    this.selectedRouteAssignmentToAssign.push(row);
                }
            } else {
                this.selectedRouteAssignmentToAssign.forEach((val, i) => {
                    if (val.id == row.id) {
                        this.selectedRouteAssignmentToAssign.splice(i, 1);
                    }
                });
                this.checkedAll = false;
            }
        },
    },
    computed: {
        isCheckedSo: function () {
            return (soCode) => {
                return this.checkedSo.includes(soCode)
            }
        },
        formatDate: () => {
            return (val) => moment.utc(val).format("YYYY-MM-DD")
        },
        setPage: {
            get() {
                return this.table.page;
            },
            set(val) {
                this.handleChangePage(val);
            },
        },
        isExpandableTableAllChecked() {
	        return this.table.data.every(item => this.checked.includes(item.ID));
	    }
    },
}
</script>
<style scoped>
.vue-daterange-picker ::v-deep .reportrange-text {
    border-radius: 5px;
    padding: 7px 10px;
}
</style>