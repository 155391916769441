<template>
    <vx-card title="Edit Picking Suggestion Form">
        <div class="vx-row mb-6" style="width: 50%" v-if="readonly">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Delivery Plan Number</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="deliveryPlan.code" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%" v-if="readonly">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Picking List Number</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="picking.code" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Picking Date Date</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <!-- <Date></Date> -->
                <flat-pickr style="width: 100%;" :config="configFlatPickr" v-model="this.pickingDate"></flat-pickr>
            </div>
        </div>
        <vs-table stripe border :data="pickingPlan">
            <template slot="thead">
                <vs-th sort-key="">Delivery Order</vs-th>
                <vs-th sort-key="">Sales Order</vs-th>
                <vs-th sort-key="">Customer Data</vs-th>
                <vs-th sort-key="">Ship-to Data</vs-th>
                <vs-th sort-key="">Request Delivery Date (RDD)</vs-th>
                <vs-th sort-key="">Delivery Type</vs-th>
            </template>

            <template>
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in pickingPlan">
                    <vs-td>{{ tr.SalesOrder.DeliveryOrder.Code }}</vs-td>
                    <vs-td>{{ tr.SalesOrder.Code }}</vs-td>
                    <vs-td>{{ tr.SalesOrder.CustomerName }}</vs-td>
                    <vs-td>{{ tr.SalesOrder.CustomerAddress }}</vs-td>
                    <vs-td>{{ formatDate(tr.SalesOrder.DeliveryOrder.Date) }}</vs-td>
                    <vs-td>{{ tr.SalesOrder.DeliveryType }}</vs-td>
                </vs-tr>
            </template>
        </vs-table>
        <br>
        <br>
        <vs-table style="width:100%" :data="pickingItem">
            <thead>
                <tr>
                    <th style="vertical-align: middle;" rowspan="2">SKU Code</th>
                    <th style="vertical-align: middle;" rowspan="2">SKU Name</th>
                    <th style="vertical-align: middle;" rowspan="2">HU</th>
                    <th style="vertical-align: middle;" rowspan="2">Quantity</th>
                    <th style="text-align: center;" colspan="3">Suggestion</th>
                </tr>
                <tr>
                    <th>Storage Area</th>
                    <th>Batch Number</th>
                    <th>Expired Date</th>
                </tr>
            </thead>
            <template>
                <template v-for="(t, indext) in pickingItem">
                    <vs-tr :key="`${indext}-${indextr}`" v-for="(tr, indextr) in t.PickingItemSuggestion">
                        <vs-td>{{ tr.SkuCode }}</vs-td>
                        <vs-td>{{ tr.ItemName }}</vs-td>
                        <vs-td>{{ tr.Unit }}</vs-td>
                        <vs-td>{{ tr.Amount }}</vs-td>
                        <!-- <vs-td>{{ tr.StorageCode }}</vs-td> -->
                        <vs-td :class="{'pb-32': selects[`select-${indext}-${indextr}`]}">
                            <vs-select v-model="tr.StorageCode">
                                <vs-select-item
                                    v-for="(item,index) in optionStorage(tr.ItemUnitID)"
                                    :key="index"
                                    :value="item"
                                    :text="item" />
                            </vs-select>
                        </vs-td>
                        <!-- <vs-td>{{ tr.Batch }}</vs-td> -->
                        <vs-td>
                            <vs-select v-model="tr.Batch" style="width: 75px;">
                                <vs-select-item
                                    v-for="(item,index) in optionBatch(tr.StorageCode, tr.ItemUnitID)"
                                    :key="index"
                                    :value="item"
                                    :text="item" />
                            </vs-select>
                        </vs-td>
                        <!-- <vs-td>{{ formatDate(tr.ExpiredDate) }}</vs-td> -->
                        <vs-td>
                            <vs-select v-model="tr.ExpiredDate" style="width: 100px;">
                                <vs-select-item
                                    v-for="(item,index) in optionExpired(tr.Batch, tr.ItemUnitID)"
                                    :key="index"
                                    :value="item"
                                    :text="formatDate(item)" />
                            </vs-select>
                        </vs-td>
                    </vs-tr>
                </template>
            </template>
        </vs-table>
        <br>
        <div class="mb-6 space-x-1 flex">
            <div class="">
                <vs-button color="primary" @click="handleSave(false)">Save</vs-button>
            </div>
            <div class="">
                <vs-button color="primary" @click="handleSave(true)">Save & Release</vs-button>
            </div>
        </div>
    </vx-card>
</template>

<script>
// import Warehouse from "./component/Warehouse.vue";
// import Date from "./component/Date.vue";
import moment from "moment";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import ConfirmDatePlugin from "flatpickr/dist/plugins/confirmDate/confirmDate.js";

export default {
    components: {
        // Warehouse,
        // Date,
        flatPickr,
    },
    props: {
        baseUrl: {
            type: String,
        },
        draw: {
            type: Number,
        },
        deliveryPlan: Object,
        picking: Object,
        date: String,
    },
    data() {
        return {
            configFlatPickr: {
                altFormat: "F j, Y",
                dateFormat: "j F Y",
                enableTime: false,
                // minTime: "00:00",
                plugins: [new ConfirmDatePlugin()],
            },
            pickingDate: this.date,
            // pickingDate: "",
            readonly: true,
            // table: this.tableDefaultState(),
            pickingPlan: [],
            pickingItem: [],
            stock: [],
            selects: {},
            warehouseId: null,
        }
    },
    computed: {
        formatDate: () => {
            return (val) => {
                const date = moment.utc(val)
                if (date.isValid()) {
                    return date.format("YYYY-MM-DD")
                }
                return "-"
            }
        },
        optionStorage: function () {
            return (itemUnitId) => {
                const res = [...new Set(this.stock.filter((s) => s.ItemUnitID == itemUnitId).map((p) => p.StorageCode))]
                if (res.length > 0) {
                    return res
                }
                return ["-"]
            }
        },
        optionBatch: function () {
            return (storage, itemUnitId) => {
                const res = [...new Set(this.stock.filter((s) => s.ItemUnitID == itemUnitId && s.StorageCode == storage).map((p) => p.Batch))]
                if (res.length > 0) {
                    return res
                }
                return ["-"]
            }
        },
        optionExpired: function () {
            return (batch, itemUnitId) => {
                const res = [...new Set(this.stock.filter((s) => s.ItemUnitID == itemUnitId && s.Batch == batch).map((p) => p.ExpiredDate))]
                if (res.length > 0) {
                    return res
                }
                return ["-"]
            }
        },
    },
    methods: {
        handleSave(release) {
            this.$vs.loading()
            const suggestion = this.pickingItem.reduce((sum, p) => [...sum, ...p.PickingItemSuggestion.map((ps) => ({id: ps.id, picking_item_id: ps.PickingItemID, storage_code: ps.StorageCode, batch: ps.Batch, expired_date: this.formatDate(ps.ExpiredDate)}))], [])
            console.log(suggestion)
            this.$http
                .post("/api/wms/v1/simple-outbound-planner/suggestion/save", {suggestion, release})
                .then((resp) => {
                    if (resp.code == 200) {
                        this.$emit("close")
                        this.$vs.loading.close();
                    } else {
                        this.$vs.loading.close();
                    }
                });
        },
    },
    mounted() {
        this.$vs.loading();
        this.$http
            .get("/api/wms/v1/simple-outbound-planner/suggestion/form", {
                params: {
                    picking_id: this.picking.id,
                    // picking_date: this.pickingDate,
                },
            })
            .then((resp) => {
                if (resp.code == 200) {
                    this.warehouseId = resp.data.WarehouseID
                    this.pickingPlan = resp.data.PickingPlan
                    this.pickingItem = resp.data.PickingItem
                //     this.$vs.loading.close();
                // } else {
                //     this.$vs.loading.close();
                }
            })
            .then(() => {
                // const itemUnitIds = this.pickingItem.reduce((sum, p) => [...sum, ...p.PickingItemSuggestion.map((ps) => ps.ItemUnitID)], [])
                const skuCode = this.pickingItem.map(pi => pi.SkuCode)
                return this.$http
                    .get("/api/wms/v1/simple-outbound-planner/stock", {
                        params: {
                            // item_unit_ids: itemUnitIds,
                            sku_codes: skuCode,
                            warehouse_id: this.warehouseId,
                            // picking_date: this.pickingDate,
                        },
                    }) 
            })
            .then((resp) => {
                if (resp.code == 200) {
                    this.stock = resp.data
                    this.$vs.loading.close();
                } else {
                    this.$vs.loading.close();
                }
            });
    },
}
</script>
