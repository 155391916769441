<template>
    <vs-tabs v-model="tabs">
        <vs-tab label="Pickup">
            <pickup @edit="edit"/>
        </vs-tab>
        <vs-tab label="Form">
            <div v-if="!allowForm"></div>
            <pickup-form v-else :do_id="doId" @close="close"/>
        </vs-tab>
    </vs-tabs>
</template>

<script>
import Pickup from "./Pickup.vue"
import PickupForm from "./Form.vue"
export default {
    components: {
        PickupForm,
        Pickup
    },
    data() {
        return {
            tabs: 0,
            doId: null,
            allowForm: false,
        }
    },
    methods: {
        edit(params) {
            console.log("params", params)
            this.allowForm = true
            this.tabs = 1
            this.doId = params.do_id
        },
        close() {
            this.allowForm = false
            this.tabs = 0
        },
    },
}
</script>

<style scoped>
.core {
  -webkit-transition: all 1s ease-out;
  -moz-transition: all 1s ease-out;
  -o-transition: all 1s ease-out;
  transition: all 1s ease-out;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 100px;
  padding: 5px;
}
</style>