<template>
    <vs-tabs v-model="tabs">
        <vs-tab label="Process">
            <process @edit="toView"></process>
        </vs-tab>
        <vs-tab label="View">
            <div v-if="!allowView"></div>
            <viewProcess v-else :deliveryPlan="deliveryPlan" :picking="picking" :date="date" @close="close"></viewProcess>
        </vs-tab>
    </vs-tabs>
</template>
<script>
import viewProcess from "./view.vue"
import process from "./process.vue"
export default {
    components: {
        process,
        viewProcess
    },
    data() {
        return {
            tabs: 0,
            deliveryPlan: {},
            picking: {},
            date: "",
            allowView: false
        }
    },
    methods: {
        toView(params) {
            this.allowView = true
            this.tabs = 1
            this.deliveryPlan = params.dp
            this.picking = params.picking
            this.date = params.date
        },
        close() {
            this.allowView = false
            this.tabs = 0
        },
    }
}
</script>