<template>
    <vs-tabs v-model="tabs">
        <vs-tab label="FCO">
            <fco :fcoID="fcoID"/>
        </vs-tab>
        <vs-tab label="LCO">
            <lco :lcoID="lcoID"/>
        </vs-tab>
    </vs-tabs>
</template>
<script>
import fco from "./fco/index.vue"
import lco from "./lco/index.vue"
export default {
    components: {
        lco,
        fco
    },
    data() {
        return {
            tabs: 0,
            fcoID: 0,
            lcoID: 0,
        }
    },
    mounted() {
        this.$http.get("/api/wms/v1/master/delivery-types").then(resp => {
            if (resp.code == 200) {
                resp.data.records.forEach(dt => {
                    switch (dt.name) {
                        case "FCO":
                            this.fcoID = dt.id
                            break;
                        case "LCO":
                            this.lcoID = dt.id
                            break;
                    }
                })
                this.$vs.loading.close();
            }
        });
    },
}
</script>