<template>
    <div>
        <vs-table search stripe border description :sst="true" :data="table.data" :max-items="table.length"
            :total="table.total" @search="handleSearch" @change-page="handleChangePage" @sort="handleSort">
            <template slot="header">
                <vs-dropdown vs-trigger-click class="cursor-pointer">
                    <div
                        class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                        <span class="mr-2">{{ this.table.start }} - {{ this.table.end }} of
                            {{ this.table.total }}</span>
                        <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                    </div>
                    <vs-dropdown-menu>
                        <vs-dropdown-item v-for="item in table.limits" :key="item" @click="handleChangelength(item)">
                            <span>{{ item }}</span>
                        </vs-dropdown-item>
                    </vs-dropdown-menu>
                </vs-dropdown>
            </template>
            <template slot="thead">
                <vs-th>View</vs-th>
                <vs-th sort-key="">Delivery Plan</vs-th>
                <vs-th sort-key="">Picking List Data</vs-th>
                <vs-th sort-key="">Delivery Order</vs-th>
                <vs-th sort-key="">Sales Order</vs-th>
                <vs-th sort-key="">Customer Data</vs-th>
                <vs-th sort-key="">Ship-to Data</vs-th>
                <vs-th sort-key="">Request Delivery Data (RDD)</vs-th>
                <vs-th sort-key="">Delivery Type</vs-th>
            </template>

            <template slot-scope="{ data }">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td>
                        <vx-tooltip text="Show Details" class="mr-4">
                            <vs-button
                                type="line"
                                icon-pack="feather"
                                icon="icon-eye"
                                v-on:click.stop="
                                handleShow()"
                            />
                        </vx-tooltip>
                    </vs-td>
                    <vs-td></vs-td>
                    <vs-td></vs-td>
                    <vs-td></vs-td>
                    <vs-td></vs-td>
                    <vs-td></vs-td>
                    <vs-td></vs-td>
                    <vs-td></vs-td>
                    <vs-td></vs-td>
                </vs-tr>
            </template>
        </vs-table>
        <div class="mb-6 space-x-1 flex">
            <div class="">
                <vs-button color="primary" @click="handleClose">Close</vs-button>
            </div>
        </div>
        <vs-pagination style="padding-top: 5px" :total="table.totalPage" v-model="setPage" />
    </div>
</template>
<script>
import moment from "moment";
export default {
    components: {},
    props: {
        baseUrl: {
            type: String,
        },
        draw: {
            type: Number,
        },
    },
    data() {
        return {
            deleteId: null,
            table: this.tableDefaultState(),
            checkedAll: false,
            checked: [],
            selectedRouteAssignmentToAssign: [],
        };
    },
    methods: {
        tableDefaultState() {
            return {
                data: [{}],
                length: 10,
                page: 1,
                search: "",
                order: "id",
                sort: "desc",
                total: 0,
                totalPage: 1,
                totalSearch: 0,
                limits: [10, 25, 50, 100, "All"],
                start: 1,
                end: 0,
            };
        },
        handleSearch(searching) {
            this.table.search = searching;
            this.table.page = 1;
            this.getData();
        },
        handleChangePage(page) {
            this.table.page = page;
            this.getData();
        },
        handleSort(key, active) {
            this.table.order = key;
            this.table.sort = active;
            this.getData();
        },
        handleChangelength(val) {
            this.table.length = val == "All" ? this.table.total : val;
            this.table.page = 1;
            this.getData();
        },
        handleClose() {
            this.getData();
            this.$emit("close");
        },
        handleShow() {
            this.$router.push({name: "simple-outbound-processing-process-view"});
        },
        getData() {
            this.$vs.loading();
            this.$http
                .get(this.baseUrl + "/table", {
                    params: {
                        length: this.table.length,
                        page: this.table.page,
                        search: this.table.search,
                        order: this.table.order,
                        sort: this.table.sort,
                        // ...
                        date: moment(this.dateNow).format("YYYY-MM-DD"),
                    },
                })
                .then((resp) => {
                    if (resp.code == 200) {
                        this.checkedAll = false;
                        this.checked = [];
                        this.selectedRouteAssignmentToAssign = [];

                        this.table.total = resp.data.total_record;
                        this.table.totalPage = resp.data.total_page;
                        this.table.totalSearch = resp.data.total_record_search;
                        this.table.length = resp.data.total_record_per_page;
                        this.table.data = resp.data.records;
                        this.setStartEnd();
                        this.$vs.loading.close();
                    } else {
                        this.$vs.loading.close();
                    }
                });
        },
        setStartEnd() {
            let valStart =
                this.table.length * this.table.page - this.table.length + 1;

            if (valStart > this.table.total) {
                valStart = 1;
            }
            if (this.table.total == 0) {
                valStart = 0;
            }
            let valEnd = this.table.length * this.table.page;

            if (valEnd > this.table.total) {
                valEnd = this.table.total;
            }

            if (
                this.table.totalSearch < this.table.total &&
                this.table.search != ""
            ) {
                valEnd = this.table.totalSearch;
            }

            this.table.start = valStart;
            this.table.end = valEnd;
        },
        handleAssign(id) {
            this.$vs.dialog({
                type: "confirm",
                color: "danger",
                title: `Confirm`,
                text: "Are you sure to assign this data?",
                accept: () => {
                    this.$vs.loading();
                    this.$http
                        .put(this.baseUrl + "/" + id + "/status", {
                            status: "Assigned",
                        })
                        .then((resp) => {
                            if (resp.code == 200) {
                                this.$vs.notify({
                                    color: "success",
                                    title: "Success",
                                    text: "The data was successfully assigned",
                                    position: "top-right",
                                    iconPack: "feather",
                                    icon: "icon-x-circle",
                                });
                            } else {
                                this.$vs.notify({
                                    color: "danger",
                                    title: "Error",
                                    text: resp.message,
                                    position: "top-right",
                                    iconPack: "feather",
                                    icon: "icon-x-circle",
                                });
                            }

                            this.$vs.loading.close();
                            this.getData();
                        })
                        .catch((error) => {
                            this.$vs.loading.close();
                            console.log(error);
                        });
                },
            });
        },
        handleAssignMultiple() {
            let routeAssignmentIDs = [];

            this.selectedRouteAssignmentToAssign.forEach((el) => {
                routeAssignmentIDs.push(el.id);
            });

            if (routeAssignmentIDs.length == 0) {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: "Please select data to assign",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                return false;
            }
            this.$vs.dialog({
                type: "confirm",
                color: "danger",
                title: `Confirm`,
                text: "Are you sure to assign this data?",
                accept: () => {
                    this.$vs.loading();
                    this.$http
                        .put(this.baseUrl + "/status/multiple", {
                            status: "Assigned",
                            route_assignment_ids: routeAssignmentIDs,
                        })
                        .then((resp) => {
                            if (resp.code == 200) {
                                this.$vs.notify({
                                    color: "success",
                                    title: "Success",
                                    text: "The data was successfully assigned",
                                    position: "top-right",
                                    iconPack: "feather",
                                    icon: "icon-x-circle",
                                });
                            } else {
                                this.$vs.notify({
                                    color: "danger",
                                    title: "Error",
                                    text: resp.message,
                                    position: "top-right",
                                    iconPack: "feather",
                                    icon: "icon-x-circle",
                                });
                            }

                            this.$vs.loading.close();
                            this.getData();
                        })
                        .catch((error) => {
                            this.$vs.loading.close();
                            console.log(error);
                        });
                },
            });
        },
        handleDraft(id) {
            this.$vs.dialog({
                type: "confirm",
                color: "danger",
                title: `Confirm`,
                text: "Are you sure change to draft this data?",
                accept: () => {
                    this.$vs.loading();
                    this.$http
                        .put(this.baseUrl + "/" + id + "/status", {
                            status: "Draft",
                        })
                        .then((resp) => {
                            if (resp.code == 200) {
                                this.$vs.notify({
                                    color: "success",
                                    title: "Success",
                                    text: "The data was successfully change to draft",
                                    position: "top-right",
                                    iconPack: "feather",
                                    icon: "icon-x-circle",
                                });
                            } else {
                                this.$vs.notify({
                                    color: "danger",
                                    title: "Error",
                                    text: resp.message,
                                    position: "top-right",
                                    iconPack: "feather",
                                    icon: "icon-x-circle",
                                });
                            }

                            this.$vs.loading.close();
                            this.getData();
                        })
                        .catch((error) => {
                            this.$vs.loading.close();
                            console.log(error);
                        });
                },
            });
        },
        handleCancel(id) {
            this.$vs.dialog({
                type: "confirm",
                color: "danger",
                title: `Confirm`,
                text: "Are you sure to cancel this data?",
                accept: () => {
                    this.$vs.loading();
                    this.$http
                        .put(this.baseUrl + "/" + id + "/status", {
                            status: "Canceled",
                        })
                        .then((resp) => {
                            if (resp.code == 200) {
                                this.$vs.notify({
                                    color: "success",
                                    title: "Success",
                                    text: "The data was successfully canceled",
                                    position: "top-right",
                                    iconPack: "feather",
                                    icon: "icon-x-circle",
                                });
                            } else {
                                this.$vs.notify({
                                    color: "danger",
                                    title: "Error",
                                    text: resp.message,
                                    position: "top-right",
                                    iconPack: "feather",
                                    icon: "icon-x-circle",
                                });
                            }

                            this.$vs.loading.close();
                            this.getData();
                        })
                        .catch((error) => {
                            this.$vs.loading.close();
                            console.log(error);
                        });
                },
            });
        },
        utcToLocal(val) {
            if (val) {
                return moment(val)
                    .add(-7, "h")
                    .utc()
                    .local()
                    .format("DD MMM YYYY HH:mm:ss");
            }
        },
        onchangeSelectedRouteAssignmentToAssign(e, index, row) {
            if (!this.checked[index]) {
                var fitered = this.selectedRouteAssignmentToAssign.filter((d) => {
                    return d.id == row.id;
                });

                if (fitered.length == 0) {
                    this.selectedRouteAssignmentToAssign.push(row);
                }
            } else {
                this.selectedRouteAssignmentToAssign.forEach((val, i) => {
                    if (val.id == row.id) {
                        this.selectedRouteAssignmentToAssign.splice(i, 1);
                    }
                });
                this.checkedAll = false;
            }
        },
    },
    mounted() { },
    watch: {
        detail() {
            this.getData();
        },

        checkedAll(val) {
            if (val) {
                this.selectedRouteAssignmentToAssign = [];
                this.table.data.forEach((el, index) => {
                    this.checked[index] = true;
                    this.selectedRouteAssignmentToAssign.push(el);
                });
            } else {
                if (
                    this.selectedRouteAssignmentToAssign.length == this.checked.length
                ) {
                    this.checked.forEach((el, index) => {
                        this.checked[index] = false;
                    });
                    this.selectedRouteAssignmentToAssign = [];
                }
            }
        },
    },
    computed: {
        setPage: {
            get() {
                return 1;
            },
            set(val) {
                this.handleChangePage(val);
            },
        },
    },
};
</script>
  