<template>
    <vx-card title="Handover Data Edit">
        <div class="vx-row" style="width: 100%">
            <div class="vx-col sm:w-1/2">
                <div class="vx-row mb-6" style="width: 100%">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Shipment Doc Number</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input readonly :value="data.TransportPlan.ShipmentNumber" class="w-full"></vs-input>
                    </div>
                </div>
                <div class="vx-row mb-6" style="width: 100%">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Handover Document</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <!-- <vs-input readonly :value="pickingListNumber" class="w-full"></vs-input> -->
                        <vs-input readonly :value="data.Code" class="w-full"></vs-input>
                    </div>
                </div>
                <div class="vx-row mb-6" style="width: 100%">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Vehicle Number</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <!-- <vs-input readonly :value="data.Vehicle.Number" class="w-full"></vs-input> -->
                        <vs-input readonly :value="data.TransportPlan.VehicleNumber" class="w-full"></vs-input>
                    </div>
                </div>
                <div class="vx-row mb-6" style="width: 100%">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Driver</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <!-- <vs-input readonly :value="data.Vehicle.DriverName" class="w-full"></vs-input> -->
                        <vs-input readonly :value="data.TransportPlan.DriverName" class="w-full"></vs-input>
                    </div>
                </div>
            </div>
            <div class="vx-col sm:w-1/2">
                <div class="vx-row mb-6" style="width: 100%">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span># of Delivery Note</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input readonly :value="data.Loading ? data.Loading.length : 0" class="w-full"></vs-input>
                    </div>
                </div>
                <div class="vx-row mb-6" style="width: 100%">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span># of COD</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input readonly :value="data.COD" class="w-full"></vs-input>
                    </div>
                </div>
                <div class="vx-row mb-6" style="width: 100%">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Handover Date</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input readonly :value="rdd" class="w-full"></vs-input>
                    </div>
                </div>
            </div>
        </div>
        <br>
        <table class="table table-stripe">
            <thead>
                <th sort-key="">Delivery Note Data</th>
                <th sort-key="">Sales Order Data</th>
                <th sort-key="">Customer Data</th>
                <th sort-key="">Ship-to Data</th>
                <th sort-key="">Request Delivery Date (RDD)</th>
                <th sort-key="">COD</th>
                <th sort-key="">Handed-over</th>
            </thead>
            <tbody>
                <tr :key="indext" v-for="(d, indext) in data.Loading">
                    <td>{{ d.Packing.DeliveryOrder.Code }}</td>
                    <td>{{ d.Packing.DeliveryOrder.SalesOrder.Code }}</td>
                    <td>{{ d.Packing.DeliveryOrder.SalesOrder.CustomerCode }} {{ d.Packing.DeliveryOrder.SalesOrder.CustomerName }}</td>
                    <td>{{ d.Packing.DeliveryOrder.DestinationCode }}</td>
                    <td>{{ d.Packing.DeliveryOrder.SalesOrder.RequestDeliveryDate }}</td>
                    <td>
                        <vs-checkbox
                            disabled
                            :value="d.Packing.DeliveryOrder.SalesOrder.COD"
                        ></vs-checkbox>
                    </td>
                    <td>
                        <vs-checkbox
                            disabled
                            :value="d.LoadingLine.some(ll => ll.HandoverLine.IsHandedover)"
                        ></vs-checkbox>
                    </td>
                </tr>
            </tbody>
        </table>
    </vx-card>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import ConfirmDatePlugin from "flatpickr/dist/plugins/confirmDate/confirmDate.js";

import moment from "moment";

export default {
    components: {
        flatPickr
    },
    props: {
        handover_id: Number,
    },
    data() {
        return {
            renderTable: true,
            table: this.tableDefaultState(),
            data: {
                // Vehicle: {},
                // TransportPlanLine: [],
            },
            // data: null,
            pickingListNumber: null,
            checkedAll: false,
            checked: [],
            rdd: moment().format("D MMMM yyyy"),
            configFlatPickr: {
                altFormat: "F j, Y",
                dateFormat: "j F Y",
                enableTime: false,
                plugins: [new ConfirmDatePlugin()],
            },
        }
    },
    computed: {
        formatDate: () => {
            return (val) => val ? moment.utc(val).format("YYYY-MM-DD") : ""
        },
    },
    methods: {
        tableDefaultState() {
            return {
                data: [{
                    SKUCode: "89898989",
                    SKUName: "ULI JONGGOL",
                    HU: "uwowowow",
                    Quantity: "98246",
                    storageArea: "MW9NF9P",
                    batchNumber: "823748280374",
                    expDate: "24 JANUARI 2027",
                }],
                length: 10,
                page: 1,
                search: "",
                order: "id",
                sort: "desc",
                total: 0,
                totalPage: 1,
                totalSearch: 0,
                limits: [10, 25, 50, 100, "All"],
                start: 1,
                end: 0,
            };
        },
        getData() {
            this.$vs.loading();
            this.$http
                .get(`/api/wms/v1/simple-outbound-planner/handover-data/${this.handover_id}`)
                .then((resp) => {
                    if (resp.code == 200) {
                        console.log(resp.data)
                        this.data = resp.data//.map
                        this.rdd = moment(this.data.Date).format("D MMMM yyyy")
                        // this.renderTable = false
                        // this.$nextTick(() => this.renderTable = true)
                        console.log(this.data)
                        // this.checkedAll = false;
                        // this.checked = [];
                        // this.selectedRouteAssignmentToAssign = [];

                        // // this.table.total = resp.data.total_record;
                        // // this.table.totalPage = resp.data.total_page;
                        // // this.table.totalSearch = resp.data.total_record_search;
                        // // this.table.length = resp.data.total_record_per_page;
                        // // this.table.data = resp.data.records;
                        // this.table.total = 1;
                        // this.table.totalPage = 1;
                        // this.table.totalSearch = 1;
                        // this.table.length = 1;
                        // this.table.data = [{
                        //     SKUCode: "89898989",
                        //     SKUName: "ULI JONGGOL",
                        //     HU: "uwowowow",
                        //     Quantity: "8055555",
                        //     storageArea: "MW9NF9P",
                        //     batchNumber: "823748280374",
                        //     expDate: "24 JANUARI 2027",
                        // }];
                        // this.setStartEnd();
                        this.$vs.loading.close();
                    } else {
                        this.$vs.loading.close();
                    }
                });
        },
        handleSave(confirm) {
            console.log("save")
            const handover = this.data.HandoverLine.map(hl => ({id: hl.id, is_handedover: hl.IsHandedover}))
            console.log(handover)
            this.$vs.loading();
            this.$http
                // .patch("/api/wms/v1/simple-outbound-planner/handover", {delivery_plan_id: [null], to_dp: true})
                .post("/api/wms/v1/simple-outbound-planner/handover", {handover_id: this.handover_id, handover: handover, confirm})
                .then((resp) => {
                    if (resp.code == 200) {
                        this.$vs.loading.close();
                        // this.getData()
                        this.$emit("close")
                    } else {
                        this.$vs.loading.close();
                    }
                })
        },
    },
    mounted() {
        console.log("mounted")
        this.getData();
    },
    watch: {
        // detail() {
        //     this.getData();
        // },
        checkedAll(val) {
            if (val) {
                this.selectedRouteAssignmentToAssign = [];
                this.table.data.forEach((el, index) => {
                    this.checked[index] = true;
                    this.selectedRouteAssignmentToAssign.push(el);
                });
            } else {
                if (
                    this.selectedRouteAssignmentToAssign.length == this.checked.length
                ) {
                    this.checked.forEach((el, index) => {
                        this.checked[index] = false;
                    });
                    this.selectedRouteAssignmentToAssign = [];
                }
            }
        },
    },
}
</script>